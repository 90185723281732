@import '/styles/config.scss';

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.spinner {
  margin: 20px 0px;
  text-align: center;

  & > div {
    width: 18px;
    height: 18px;

    margin: 0px 5px;
    background-color: $color-brand-primary;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    
    &.bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &.bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
      background-color: $color-brand-secondary;
    }
  }

  &.small {
    margin: 0px 0px;
    text-align: inherit;
    display: inline-block;

    & > div {
      width: 0.5em;
      height: 0.5em;

      margin: 0 0.1em;
    }
  }
}
