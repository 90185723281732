
.layer-login {
  width: 100vw;
  height: 100vh;

  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;

  .form-signin {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: 0 auto;

    -webkit-box-shadow: 0px 3px 1px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 1px 1px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 1px 1px rgba(0,0,0,0.15);

    .cover {
      margin: -15px;
    }

    .checkbox {
      font-weight: 400;
    }

    .form-group {
      margin-bottom: 0px;
    }

    .form-control {
      position: relative;
      box-sizing: border-box;
      height: auto;
      padding: 10px;
      font-size: 16px;

      &:focus {
        border: 1px solid #ced4da;
      }
    }

    .form-control:focus {
      z-index: 2;
    }

    input {
      outline: none;
    }

    #email {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    #password {
      margin-bottom: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
