
.command-string {
  background: #f1f1f1;
  padding: 10px;
  color: #444444;
  margin-bottom: 0px;
}

.log {
  background: #f1f1f1;
  padding: 10px;

  code {
    display: block;
    white-space: pre-wrap;
  }
}