@import '/styles/config.scss';

.bm-menu {
  background-color: $color-brand-primary;
  padding-top: 0px;
  height: 100%;
  color: $color-brand-font;

  -webkit-box-shadow: 3px 0px 1px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 3px 0px 1px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 3px 0px 1px 0px rgba(0, 0, 0, 0.15);

  .logo {
    background-color: $color-brand-mute;
    text-align: center;

    img {
      width: 100%;
    }
  }
}
