
.btn {
  box-shadow: none !important;
  border-radius: 0px;
  text-align: center;
  font-weight: bold;

  &.btn-primary {
    background-color: $color-brand-primary;
    border-color: $color-brand-primary;
    color: $color-brand-font;
  }

  &.disabled,
  &:disabled {
    .btn-primary {
      background-color: $color-brand-mute;
      border-color: $color-brand-mute;
      color: $color-brand-font;
    }

    font-weight: normal;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus,
    &.active {
      &.btn-primary {
        background-color: $color-brand-secondary;
        border-color: $color-brand-secondary;
        color: $color-brand-font;
      }
    }
  }
}

.form-control {
  border-radius: 0px;

  &:focus {
    border: 1px solid #ced4da;
    -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15) !important;
    -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15) !important;
    box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15) !important;
  }
}

label {
  font-weight: lighter;
  margin-bottom: 0px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #888888;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 20px;
}

@-webkit-keyframes btn-loading {
  0%, 80%, 100% {
    background-color: $color-brand-font;
  }
  40% {
    background-color: $color-brand-primary;
  }
}

@keyframes btn-loading {
  0%, 80%, 100% { 
    background-color: $color-brand-font;
  }
  40% { 
    background-color: $color-brand-primary;
  }
}

.btn.loading {
  &:after {
    content: '';
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3em;
    border-radius: 50%;
    margin-bottom: 0.2em;

    -webkit-animation: btn-loading 1.4s infinite ease-in-out both;
    animation: btn-loading 1.4s infinite ease-in-out both;
  }
}

.btn.is-submit-error{
  color: red !important;
}

summary{
  outline: none
}