@import "~bootstrap/scss/bootstrap";
@import '/styles/config.scss';
@import '/styles/theme.scss';

.layer-app {
  font-family: $font-primary;

  main {
    width: 100vw;

    margin-left: 0px;

    @include media-breakpoint-up(md) {
      width: calc(100vw - #{$sidebar-width});
      margin-left: $sidebar-width;
    }
  }

}
