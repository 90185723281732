@import '/styles/config.scss';

header {
  height: $header-height;
  line-height: $header-height;
  padding: 0px;
  margin: 0px;

  .client {
  	float: right;
  	padding: 0em 1em;
  	background: #d6efa1;
  	color: #38461a;
  }
}
