.configuration-item {
    margin: 1em 0px;

    .info {
        margin-bottom: 1em;

        .title {
            font-size: 1.2em;
        }    
    }
    
    .switch {
        text-align: right;
    }

    .additional-config {
        padding: 1em;
        background-color: #f1f1f1;
    }

    &:after {
        content: '';
        display: block;
        width: 100%;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 1em;
    }
}