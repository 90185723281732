@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300');

$mobile-max-width: 768px;

$font-primary: 'Open Sans', sans-serif;
$font-primary-condensed: 'Open Sans Condensed', sans-serif;

$sidebar-width: 160px;
$header-height: 40px;

$color-brand-primary: #c2f98c;
$color-brand-secondary: #a1e260;
$color-brand-mute: #e7fdd2;
$color-brand-font: #4f8c04;

$color-severity-low: #6bb633;
$color-severity-medium: #f3b633;
$color-severity-high: #e14e0f;

$color-severity-good: #6bb633;
$color-severity-bad: #e14e0f;

$color-background-primary: #ededed;
$color-background-secondary: #f6f6f6;

@import '/styles/theme.scss';
