
.action {
  margin: 0px 5px;
  cursor: pointer;
  color: #888888;

  &:hover {
    color: #888888;
  }
}
