@import "~bootstrap/scss/bootstrap";
@import '/styles/config';

.menu-item {
  display: block;
  line-height: 22px;
  padding: 5px;
  color: $color-brand-font;
  text-decoration: none;

  @mixin active() {
    text-decoration: none;
    background-color: $color-brand-secondary;
    color: $color-brand-font;

    .icon {
      color: $color-brand-font;
    }
  }

  @include media-breakpoint-up(md) {
    &:hover {
      @include active();
    }
  }

  &.active {
    @include active();
  }

  .icon {
    width: 30px;
    margin-right: 5px;
  }
}
