
.pagination {
  .next, .prev {
    width: 50px;
    line-height: 40px;
    margin-right: 5px;
    padding: 0px;
  }

  .content-range {
    line-height: 40px;
    text-align: right;
    font-size: 30px;
    color: #8ed181;
    margin-left: 10px;
  }
}
