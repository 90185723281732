.input-survey-id-container {
  position: relative;

  .survey-id-helper-container {
    max-height: 200px;
    border: 1px solid #ced4da;
    overflow-x: auto;
    position: absolute;
    margin-top: -17px;
    width: 100%;
    background-color: #fff;
    z-index: 1;
  }

  .survey-id-helper-option {
    padding: 0px 0 0px 5px;
    font-size: 14px;
    border-bottom: 1px solid #f1f1f1;

    &:hover {
      background-color: #c2f98c;
      cursor: pointer;
    }
  }
}
