
.card {
  -webkit-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.15);

  margin-bottom: 10px;
  border-radius: 0px;
  padding: 0.5em;

  .actions {
    display: none;
  }

  &:hover {
    .actions {
      display: block;
    }
  }
}
